<template>
    <div class="container" style="margin-top:50px;" >
        <div class="mony row">
            <div class="col-xl-3 bl">
                <div class="pay-left" id="channel_menu_div">
                    <ul>
                        <li v-for="item in select" :key="item.id" :class="[currentId==item.id?'active':'']" @click="currentId = item.id">
                            <a class="channel-a" href="javascript:void(0);" id="channel-54" data-type="54">
                                <img :src="item.img" alt="">
                                <span class="channel-name">{{item.name}}</span><span class="channel-status channel-status-0"></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-xl-9 br">
                <div id="order_table" class="order-table">
                    <div class="payment-method">{{select[currentId].name}}</div>
                    <!--用户名-->
                    <div id="pay_account" class="order-tr cf pr order-account-name">
                        <div>充值账号：</div>
                        <input type="text" v-model="login">
                        <div id="pay_logs" class="pay-logs">
                        </div>
                    </div>
                    <!-- 充值地点 -->
                     <div id="pay_local"  class="order-tr cf pr order-account-name">
                        <div>充值位置：</div>
                        <div class="pay_local_where">
                             <a class="pay-amount" v-for="(item,i) in local" :key="item"  v-bind:class="{classred:classenable&&i==currentclass}" @click="classmoney(i)">
                                 <span>{{item}}</span>
                                 <img class="ok" style="display:none;" v-bind:class="{classout:classenable&&i==currentclass}" src="../assets/images/ok.jpg" alt="">
                                 <span class="order-checked"></span>
                            </a> 
                        </div>
                    </div>
                    <!--选择金额-->
                    <div id="pay_amount" class="order-tr cf order-amount" style="display:block">
                        <div>选择金额：</div>
                        <div id="pay_amount_arr" >
                            <a class="pay-amount" v-for="(item,i) in monys" :key="item" v-bind:class="{classred:classenable&&i==current}"  @click="newmoney(i)">
                                <span>{{item}}</span><span class="order-checked"></span>
                                <img class="ok" style="display:none;" v-bind:class="{classout:classenable&&i==current}" src="../assets/images/ok.jpg" alt="">
                                <div class="contomize" style="display:none;"  v-bind:class="{classout:classenable&&i==11}">
                                <input type="text" v-model="value11" placeholder="其它">
                                <span>元</span>
                            </div>
                            </a>                            
                        </div>
                    </div>
                </div>
                <!--提交按钮-->
                <div class="money"  @click="mony">立即充值</div>
                <!--温馨提示-->
                <div id="pay_help" class="order-tr cf order-help order-help-0" style="display: block;">
                    <div class="order-remit-process">
                        <div class="pay-info-help"><p class="alerttxt_div">
                            <b>温馨提示</b>：<br>
                            1、使用支付宝帐户支付的用户，请保证您的支付宝帐户有足够的现金。<br>
                            2、本充值方式同时支持国内常用银行的在线银行支付。<br>
                            3、支付宝扫码支付：只要您的手机安装了支付宝钱包，就可以进行扫码支付。<br>
                            4、请您关闭所有屏蔽弹出窗口之类的功能，否则在线支付将无法继续，比如：3721、上网助手、google toolbar、alexa toolbar、baidu等。<br>
                            5、充值成功后需要1-2分钟到帐，请耐心等待！如果您需要充值咨询，请致电我们的客服：0515-88115151 0515-88118151
                        </p></div>
                    </div>
                </div>
            </div>
        </div>
        <footer-info></footer-info>
    </div>
</template>
<script>
import FooterInfo from "../components/footer";
import wx from "../assets/wx.gif";
import zfb from "../assets/zfb.png";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    FooterInfo
  },
   computed: {
    ...mapState(["login"])
  },
  watch: {
    value11: function(v) {
      if (v) {
        this.currentMony = "";
      } else {
        this.currentMony = "10元";
      }
    }
  },
  data() {
    return {
      value11: "",
      currentId: 1,
      current: 0,
      currentclass: 0,
      classenable: true,
      currentMony: "10元",
      local: [ "开心豆"],
      monys: [
        "10元",
        "20",
        "50",
        "100元",
        "200元",
        "500元",
        "1000元",
        "2000元",
        "3000",
        "5000元",
        "10000元",
        " "
      ],
      select: [
        {
          img: wx,
          name: "微信支付",
          id: 0
        },
        {
          img: zfb,
          name: "支付宝",
          id: 1
        }
      ]
    };
  },
  methods: {
     ...mapMutations(["set_login", "set_authStatus"]),
    mony() {
      if(this.login){
        this.$Message.info("抱歉，充值功能暂未开放!");
      }else{
        this.$Message.info("请前往首页登录！");
      }
    },
    newmoney(i) {
      this.classenable = true;
      this.current = i;
    },
    classmoney(i) {
      this.classenable = true;
      this.currentclass = i;
    }
  }
};
</script>

<style>
.order-tr {
  padding: 15px 0 15px 46px;
  color: #333;
  font-size: 14px;
  display: flex;
}
.order-td-right {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid blue;
}

.order-td-left {
  width: 85px;
}
.ok {
  position: absolute;
  right: 0;
  bottom: 0;
}
.pay-amount-on,
.pay-amount:hover {
  color: #333;
  line-height: 42px;
  border: 2px solid #f93;
}
.bl {
  background-color: #f5f5f5;
  padding: 0;
}
.bl li {
  line-height: 80px;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-bottom: 1px solid #e1e1e1;
  text-indent: 32px;
}
.bl li img {
  margin-right: 16px;
  float: initial;
}
.bl li a {
  color: #000 !important;
}
.bl li.active {
  border-right: 0;
  background-color: #fff;
  border-left: 3px solid #f93;
  border-bottom: 1px solid #f93;
}
body {
  background-color: #fafafa;
}
.classout {
  display: block !important;
}
.mony {
  margin-top: 32px;
  background-color: #fff;
}
.classred {
  color: #333 !important;
  line-height: 42px !important;
  border: 2px solid #f93 !important;
}
.left-vertical-tabs {
  padding: 12px 24px;
  border-radius: 6px;
  background-color: #f8f9fa;
}
.right-text-tabs {
  padding: 12px 24px;
}
.tabs .nav {
  display: block;
}
.tabs .nav li {
  margin-bottom: 12px;
}
.btn-outline-secondary {
  margin-right: 12px;
}
.money {
  color: #fff;
  background-color: #f90;
  border-color: #ffc107;
  width: 188px;
  height: 54px;
  line-height: 54px;
  margin: 40px auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
   cursor: pointer;
  
}
/* 付款方式 */
.payment-method {
  padding: 20px 0px 0px 46px;
  font-size: 24px;
  color: #000;
}
#pay_account {
  line-height: 44px;
}
#pay_account input {
  width: 195px;
  margin-left: 30px;
  height: 44px;
  border-radius: 5px;
  padding: 0 5px;
  border: 1px solid #ccc;
}
#pay_amount {
  position: relative;
  height: 300px;
  display: flex;
  flex-direction: row;
}
#pay_amount_arr {
  position: absolute;
  top: 0;
  right: 0;
  width: 678px;
}
.pay-amount {
  position: relative;
  display: block;
  width: 200px;
  height: 44px;
  text-align: center;
  margin-bottom: 10px;
  line-height: 44px;
  margin-right: 26px;
  float: left;
  border-radius: 4px;
  border: 1px solid #e1dedc;
}
.contomize {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 0;
  background: #e1e1e1;
}
#pay_amount_arr .contomize input {
  margin-top: 2.5px;
  width: 150px;
  background: #fff;
  height: 35px;
  border-radius: 5px;
  padding-left: 10px;
  border: 1px solid #ccc;
}
#pay_amount_arr .contomize span {
  margin-left: 10px;
}

.pay_local_where {
  margin-left: 30px;
}
#pay_help {
  color: #999;
  padding-top: 50px;
  padding-bottom: 100px;
  font-size: 14px;
}
</style>
